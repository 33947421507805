@keyframes lightUpText {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.light-up-text {
  color: #ffffff;
  background: linear-gradient(to right, #FFFFFF 0%, #FFFFFF 50%, #9CA3AF 50%, #9CA3AF 100%);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: lightUpText 3s linear infinite;
  animation-delay: 2s; /* Pausa antes de reiniciar */
}

