@tailwind base;
@tailwind components;
@tailwind utilities;

/* Añade esto en tu archivo CSS global */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}